<template>
  <span class="font-bold">
    <span v-if="!shift?.start" class="text-red-600">Shift nog niet gestart</span>
    <span v-else-if="!shift?.aangekomen" class="text-orange-400">Onderweg naar klant</span>
    <span v-else-if="!shift?.klant_ingestapt" class="text-orange-400">Wachtend op de klant</span>
    <span v-else-if="!shift?.klant_afgezet" class="text-orange-400">Klant opgehaald, maar nog niet afgezet</span>
    <span v-else-if="!shift?.einde" class="text-orange-400">Klant afgezet, maar shift nog niet beeïndigd</span>
    <span v-else-if="shift?.einde" class="text-green-500">Shift ingevuld</span>
  </span>
</template>

<script>
export default {
  props: {
    shift: Object,
  },
}
</script>
